import React, {useRef, useState} from 'react';

import '../stylesheets/AudioControl.scss';
import {getAudioTranscript} from "../../utils/fetch/speechFetching";

const AudioControl = ({ onStateChange, audioState }) => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('Media Devices API or getUserMedia not supported in this browser.');
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      recordedChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        onStateChange('processing');
        const audioBlob = new Blob(recordedChunksRef.current, { type: "audio/webm;codecs=opus" });

        try {
          const transcriptRes = await getAudioTranscript(audioBlob);

          console.log('Audio recording stopped and processed.', transcriptRes);

          onStateChange('ready', transcriptRes);

        } catch (e) {

        }
      };

      mediaRecorderRef.current.start(1000);
      setIsRecording(true);
      onStateChange('listening');
    } catch (err) {
      console.error('Failed to start recording:', err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  return (
    <div className={'flex-grow-1 col-12 my-2 py-2 audio-control '+ audioState}>
      <button onClick={toggleRecording}></button>
    </div>
  );
};

export default AudioControl;
