import axios from "axios";
import {getLocal} from "../dataFetching";

const getLLMResponse = async ()=> {
  try {
    const sessId = getLocal('session_id');

    if (!sessId) {
      throw new Error('Session ID not found');
    }

    const response = await axios.get(`${process.env.REACT_APP_API}/api/LLM-process/` + sessId);

    console.log(response);
    return response;
  } catch (e) {
    console.log(e);
    throw new Error('An error occurred while getting LLM Response');
  }
}

export { getLLMResponse }
