// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
  display: flex;
  height: 100%;
  overflow-y: auto;
  background-color: #151c3b; }

.transcript .chat-container {
  width: 89%; }

@media (min-width: 992px) and (orientation: landscape) {
  .avatar .chat-container {
    flex: 0 0 auto;
    max-width: 44%;
    max-width: calc(100vh - 7rem - 4.3vw - 64px); } }

@media screen and (orientation: portrait) {
  .transcript .chat-container {
    width: 75%; } }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/Chat.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,yBAAyB,EAAA;;AAG3B;EACE,UAAU,EAAA;;AAGZ;EACE;IACI,cAAc;IACd,cAAc;IACd,4CAA4C,EAAA,EAC/C;;AAGH;EACE;IACE,UAAU,EAAA,EACX","sourcesContent":[".chat-container {\n  display: flex;\n  height: 100%;\n  overflow-y: auto;\n  background-color: #151c3b;\n}\n\n.transcript .chat-container {\n  width: 89%;\n}\n\n@media (min-width: 992px) and (orientation: landscape) {\n  .avatar .chat-container {\n      flex: 0 0 auto;\n      max-width: 44%;\n      max-width: calc(100vh - 7rem - 4.3vw - 64px);\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .transcript .chat-container {\n    width: 75%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
