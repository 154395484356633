// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/bg/default.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./images/bg/landscape.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./images/bg/portrait.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: #151c3b;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .App.avatar, .App.transcript {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___}); }
  .App.avatar .transcript-container,
  .App.transcript .avatar-container {
    display: none !important; }

@media screen and (max-width: 1180px) and (orientation: landscape) {
  .App.avatar, .App.transcript {
    background-size: 100% auto; } }

@media screen and (orientation: portrait) {
  .App.avatar, .App.transcript {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___}); } }
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,yDAAgD;EAChD,4BAA4B;EAC5B,sBAAsB;EACtB,+BAA+B;EAC/B,yBAAyB;EACzB,YAAY;EACZ,aAAY;EACZ,uBAAuB;EACvB,mBAAmB,EAAA;EATrB;IAaI,yDAAkD,EAAA;EAbtD;;IAkBI,wBAAwB,EAAA;;AAI5B;EACE;IAGI,0BAA0B,EAAA,EAC3B;;AAIL;EACE;IAGI,yDAAiD,EAAA,EAClD","sourcesContent":[".App {\n  background-image: url('./images/bg/default.png');\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: top center;\n  background-color: #151c3b;\n  height: 100%;\n  display:flex;\n  justify-content: center;\n  align-items: center;\n\n  &.avatar,\n  &.transcript {\n    background-image: url('./images/bg/landscape.png');\n  }\n\n  &.avatar .transcript-container,\n  &.transcript .avatar-container {\n    display: none !important;\n  }\n}\n\n@media screen and (max-width: 1180px) and (orientation: landscape) {\n  .App {\n    &.avatar,\n    &.transcript {\n      background-size: 100% auto;\n    }\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .App {\n    &.avatar,\n    &.transcript {\n      background-image: url('./images/bg/portrait.png');\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
