// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/avatar.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/transcript.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-mode {
  position: fixed;
  top: 16px;
  right: 16px; }
  .switch-mode button {
    height: 36px;
    width: 36px;
    background-size: contain;
    background-color: transparent;
    border: 0; }
  .switch-mode.transcript button {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); }
  .switch-mode.avatar button {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___}); }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/SwitchMode.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,WAAW,EAAA;EAHb;IAMI,YAAY;IACZ,WAAW;IACX,wBAAwB;IACxB,6BAA6B;IAC7B,SAAS,EAAA;EAVb;IAcM,yDAAgD,EAAA;EAdtD;IAmBM,yDAAoD,EAAA","sourcesContent":[".switch-mode {\n  position: fixed;\n  top: 16px;\n  right: 16px;\n\n  button {\n    height: 36px;\n    width: 36px;\n    background-size: contain;\n    background-color: transparent;\n    border: 0;\n  }\n  &.transcript {\n    button {\n      background-image: url('../../images/avatar.png');\n    }\n  }\n  &.avatar {\n    button {\n      background-image: url('../../images/transcript.png');\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
