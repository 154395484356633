import axios from "axios";
import {getLocal, setLocal} from "../dataFetching";

const getHistory = async (key)=> {
  try {
    const sessId = getLocal('session_id');

    if (!sessId) {
      setLocal('auth_token', '');
      window.location.reload();
      throw new Error('Session ID not found');
    }

    const response = await axios.get(`${process.env.REACT_APP_API}/api/sessions/` + sessId);
    const hist = response.data.session?.displayContents?.map(c => {return {text: c.parts[0].text, sender: c.role === "user" ? "Human" : "AI"};});

    console.log(hist);
    if (!hist) {
      setLocal('session_id', '');
      setLocal('auth_token', '');
      window.location.reload();
    }
    return hist;
  } catch (e) {
    console.log(e);
    throw new Error('An error occurred while getting history');
  }
}

const clearHistory = async ()=> {
  try {
    const sessId = getLocal('session_id');

    if (!sessId) {
      throw new Error('Session ID not found');
    }

    const response = await axios.delete(`${process.env.REACT_APP_API}/clear/` + sessId);

    console.log(response);
    window.location.reload();
    return response;
  } catch (e) {
    console.log(e);
    throw new Error('An error occurred while clearing history');
  }
}


export { getHistory, clearHistory }
