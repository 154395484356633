// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
  margin-bottom: 1.25em;
  padding: 0.5em 1em;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 80%;
  border-radius: 10px;
  font-size: 1.25em; }

.message.ai {
  background-color: #425891;
  color: white;
  align-self: flex-start; }

.message.human {
  background-color: #e5eefb;
  align-self: flex-end; }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/Message.scss"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,uBAAkB;EAAlB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;EACnB,iBAAiB,EAAA;;AAGnB;EACE,yBAAyB;EACzB,YAAY;EACZ,sBAAsB,EAAA;;AAGxB;EACE,yBAAyB;EACzB,oBAAoB,EAAA","sourcesContent":[".message {\n  margin-bottom: 1.25em;\n  padding: 0.5em 1em;\n  width: fit-content;\n  max-width: 80%;\n  border-radius: 10px;\n  font-size: 1.25em;\n}\n\n.message.ai {\n  background-color: #425891;\n  color: white;\n  align-self: flex-start;\n}\n\n.message.human {\n  background-color: #e5eefb;\n  align-self: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
