import '../stylesheets/Header.scss';
import logo from '../../images/logo.png';

function Header () {

  return <div className="flex-grow-1 header-container my-2 py-4 d-flex justify-content-center align-items-center">
    <img onClick={() => window.location.reload()} src={logo} alt="Trainer AI" />
  </div>;
}

export default Header;
