import '../stylesheets/ClearHistory.scss';
import {clearHistory} from "../../utils/fetch/historyFetching";

function ClearHistory () {

  const handleClick = async () => {
    try {
      if (window.confirm('Press OK to clear the chat transcript, this operation can\'t be undone.')) {
        const transcriptRes = await clearHistory();
        console.log('History cleared', transcriptRes);
      }
    } catch (e) {

    }
  }

  return <div className="clear-history-container d-flex justify-content-center align-items-center">
    <button onClick={handleClick}></button>
  </div>;
}

export default ClearHistory;
