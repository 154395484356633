import axios from "axios";
import {getLocal} from "../dataFetching";

const getAudioTranscript = async (audioData)=> {
  try {
    const responsePromise = new Promise((resolve, reject) => {
      console.log("inside start of responsePromise");
      const reader = new FileReader();
      reader.readAsDataURL(audioData);
      reader.onloadend = async () => {
        try {
          console.log("in reader.onloadend");
          const base64data = reader.result;
          let params = { audioData: base64data, sessionId: getLocal('session_id') };

          const res = await axios.post(`${process.env.REACT_APP_API}/api/transcribe-audio/`, params);
          resolve(res.data);
        } catch (e) {
          console.log("Error POSTING");
          console.log(e);
          reject(e);
        }
      }
    });

    const apiResponse = await Promise.all([responsePromise]);
    console.log("apiResponse:");
    console.log(apiResponse);
    return apiResponse[0];
  } catch (e) {
    throw new Error(e);
  }
}

export { getAudioTranscript }
