// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clear-history-container {
  position: fixed;
  bottom: 16px;
  left: 16px; }
  .clear-history-container button {
    height: 36px;
    width: 36px;
    background-size: contain;
    background-color: rgba(255, 255, 255, 0.05);
    border: 0;
    border-radius: 50%; }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/ClearHistory.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,UAAU,EAAA;EAHZ;IAMI,YAAY;IACZ,WAAW;IACX,wBAAwB;IACxB,2CAAwC;IACxC,SAAS;IACT,kBAAkB,EAAA","sourcesContent":[".clear-history-container {\n  position: fixed;\n  bottom: 16px;\n  left: 16px;\n\n  button {\n    height: 36px;\n    width: 36px;\n    background-size: contain;\n    background-color: rgba(255,255,255,0.05);\n    border: 0;\n    border-radius: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
