const moment = require('moment');
const momentZ = require('moment-timezone');

const formatDuration = (minutes) => {
  const duration = moment.duration(minutes, 'minutes');
  const hours = Math.floor(duration.asHours());
  const mins = Math.floor(duration.asMinutes()) % 60;

  return `${hours} hours ${mins} minutes`;
}

const formatSpanInDays = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);

  return endDate.diff(startDate, 'days') + ' days';
}

const formattedDate = (d) => {
  const currentDate = d ? new Date(d) : new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  const fd = `${year}-${month}-${day}`;
  return fd;
}

const formatTimeWithZone = (t) => {
  const [dt,tm] = t.split('T');
  const [timestamp] = tm.split('-');

  const formattedTime = momentZ(dt + 'T' + timestamp).format('h:mm A z');
  return formattedTime;
}

function generateRandomString(l = 6) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let charactersLength = characters.length;
  for (let i = 0; i < l; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export {
  formatDuration,
  formatSpanInDays,
  formattedDate,
  formatTimeWithZone,
  generateRandomString
}
