// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/audio/ready.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/audio/listening.gif", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../images/audio/processing.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audio-control {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0; }
  .audio-control button {
    height: 4em;
    width: 4em;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: contain;
    background-color: transparent;
    border: 0;
    border-radius: 50%; }
  .audio-control.listening button {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___}); }
  .audio-control.processing button {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___}); }

.avatar-container.error .audio-control button, .avatar-container.failed .audio-control button {
  box-shadow: inset 0 0 5px 5px red; }

.avatar-container.connecting .audio-control button {
  box-shadow: inset 0 0 5px 5px yellow; }

@media screen and (orientation: portrait) {
  .audio-control {
    height: 17.97vh; }
    .audio-control button {
      height: 96px;
      width: 96px; } }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/AudioControl.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS,EAAA;EAJX;IAOI,WAAW;IACX,UAAU;IACV,yDAAqD;IACrD,wBAAwB;IACxB,6BAA6B;IAC7B,SAAS;IACT,kBAAkB,EAAA;EAbtB;IAkBM,yDAAyD,EAAA;EAlB/D;IAuBM,yDAA0D,EAAA;;AAKhE;EAKQ,iCAAiC,EAAA;;AALzC;EAaQ,oCAAoC,EAAA;;AAM5C;EACE;IACE,eAAe,EAAA;IADjB;MAGI,YAAY;MACZ,WAAW,EAAA,EACZ","sourcesContent":[".audio-control {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  bottom: 0;\n\n  button {\n    height: 4em;\n    width: 4em;\n    background-image: url('../../images/audio/ready.png');\n    background-size: contain;\n    background-color: transparent;\n    border: 0;\n    border-radius: 50%;\n  }\n\n  &.listening {\n    button {\n      background-image: url('../../images/audio/listening.gif');\n    }\n  }\n  &.processing {\n    button {\n      background-image: url('../../images/audio/processing.gif');\n    }\n  }\n}\n\n.avatar-container {\n  &.error,\n  &.failed {\n    .audio-control {\n      button {\n        box-shadow: inset 0 0 5px 5px red;\n      }\n    }\n  }\n\n  &.connecting {\n    .audio-control {\n      button {\n        box-shadow: inset 0 0 5px 5px yellow;\n      }\n    }\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .audio-control {\n    height: 17.97vh;\n    button {\n      height: 96px;\n      width: 96px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
