import '../stylesheets/SwitchMode.scss';

const SwitchMode = ({step, handleSwitchMode}) => {

  const handleClick = () => {
    handleSwitchMode(step === 'transcript' ? 'avatar' : 'transcript');
  }

  return <div className={'switch-mode ' + step}>
    <button onClick={handleClick}></button>
  </div>
}

export default SwitchMode;
