import Transcript from "./Transcript";
import Avatar from "../general/Avatar";

import '../stylesheets/Chat.scss';
import SwitchMode from "./SwitchMode";
import Header from "../general/Header";
import ClearHistory from "../general/ClearHistory";

const Chat = ({step, setStep}) => {
  const handleSwitchMode = (md) => {
    setStep(md);
  }

  return (<div className="chat-container col-9 d-flex flex-column justify-content-start align-items-center">
      <Header />
      <Avatar />
      <Transcript step={step} />
      <SwitchMode step={step} handleSwitchMode={handleSwitchMode} />
      <ClearHistory />
    </div>
  );
}

export default Chat;
