// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login-container {
  background-color: #eeeeee;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto; }
  .Login-container form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center; }
    .Login-container form > div {
      margin-bottom: 1em; }
    .Login-container form input {
      text-align: center; }
  .Login-container h2 {
    margin-bottom: 1.5rem; }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/Login.scss"],"names":[],"mappings":"AAAA;EACI,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,cAAc,EAAA;EANlB;IAQI,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,mBAAmB,EAAA;IAXvB;MAaM,kBAAkB,EAAA;IAbxB;MAgBM,kBAAkB,EAAA;EAhBxB;IAoBI,qBAAqB,EAAA","sourcesContent":[".Login-container {\n    background-color: #eeeeee;\n    padding: 2rem;\n    border-radius: 8px;\n    text-align: center;\n    max-width: 400px;\n    margin: 0 auto;\n  form {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n    align-items: center;\n    > div {\n      margin-bottom: 1em;\n    }\n    input {\n      text-align: center;\n    }\n  }\n  h2 {\n    margin-bottom: 1.5rem;\n  }\n\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
