import {useMutation, useQuery, useQueryClient} from "react-query";
import {getHistory} from "../fetch/historyFetching";

function useGetHistory() {
  const { status, data, error, isError, isFetching, isLoading } = useQuery(
    ["history"],
    getHistory,
    {
      staleTime: 60000
    }
  );
  return { status, data, error, isError, isFetching, isLoading };
}

function useAddHistory() {
  const queryClient = useQueryClient();

  return useMutation((body) => body, {
    onSuccess: (data) => {
      queryClient.setQueryData(["history"], (prev) => {
        prev.push(data);
        return prev;
      });
    },
  });
}

export {
  useGetHistory,
  useAddHistory
}
