import React, {useCallback, useEffect, useState} from 'react';
import './App.scss';

import {Route, Routes, useNavigate} from "react-router-dom";
import {getLocal} from "./utils/dataFetching";
//import Header from "./components/general/Header";
import Login from "./components/general/Login";
import Chat from "./components/chat/Chat";
import {useAuth} from "./context/AuthContext";

function App() {
  document.title = 'AETC AI Trainer';
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const pth = () => {
    const p = window.location.pathname.split('/');
    return p.length > 0 ? p[1] : '';
  }

  const [step, setStep] = useState(pth());

  const handleLoginSuccess = (ccode) => {
    // company.config = JSON.parse(company.config);
    // sessionStorage.setItem('company',JSON.stringify(company));
    setStep('avatar');
  };

  const handleLogout = useCallback((e) => {
    e?.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    navigate('/');
    setStep('');
  },[navigate]);

  useEffect(() => {
    //console.log('use EFFECT');
    async function fetchToken() {
      try {
        const token = getLocal("auth_token");
        if (!token) {
          // const API_TOKEN = await getToken();
          // localStorage.setItem("auth_token", API_TOKEN);
        }
      } catch (error) {}
    }

    fetchToken();

    return () => {
      //console.log('removing auth token');
      //localStorage.removeItem("auth_token");
    };
  }, []);

  useEffect(() => {
    //console.log('use EFFECT1');
    const currentSeg = pth();

    if (step && step !== currentSeg) {
      //console.log('use EFFECT2');
      navigate('/'+step);
      return;
    }

    if (isAuthenticated) {
      if (!/^(avatar|transcript)$/.test(currentSeg)) {
        //console.log('use EFFECT3');
        setStep('avatar');
        navigate('/avatar');
        return;
      }
    }
    else {
      if (currentSeg !== '') {
        console.log('use EFFECT4');
        setStep('');
        navigate('/');
        return;
      }
    }

  }, [isAuthenticated, navigate, step, handleLogout]);


  return (
    <div className={`col-12 App ${step}`}>
      {/*<Header handleLogout={handleLogout} isAuthenticated={isAuthenticated} />*/}
      {isAuthenticated ? (
        <Routes>
          <Route path="/avatar" element={<Chat step={step}
                                             setStep={setStep} />} />
          <Route path="/transcript" element={<Chat step={step}
                                               setStep={setStep} />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Login onLoginSuccess={handleLoginSuccess} />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
