import '../stylesheets/Login.scss';
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from "../../context/AuthContext";

const Login = ({ onLoginSuccess }) => {
  const [ccode, setccode] = useState('');
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { login } = useAuth();

  // Memoizing attemptLogin using useCallback
  const attemptLogin = useCallback(async (ccode) => {
      setError("");
      setSubmitted(true);
      console.log('attempting Login', ccode);

      try {
        let loginResult = await login(ccode);
        console.log(loginResult);

        if (loginResult === 'wait') {
          return;
        }
        if (loginResult.status === 200) {
          onLoginSuccess(ccode);
        } else if (loginResult.status === 'error') {
          setError('Invalid key');
        } else {
          setError(loginResult && !loginResult.success ? loginResult.message : "An unexpected error occurred.");
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setSubmitted(false);
      }
  }, [login, onLoginSuccess]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const key = queryParams.get('key');
    // const al = async () => {
    //   await attemptLogin(key);
    // }
    console.log('effect',ccode,key);

    if ((key || ccode) && ccode !== key && (submitted || !ccode)) {
      if (!ccode) {
        setccode(key);
      }
      setSubmitted(false);
      attemptLogin(ccode || key);
    }
  }, [ attemptLogin, ccode, submitted]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  return (
    <div className="Login-container col-12">
      <h2>Login</h2>
      <form onSubmit={handleFormSubmit}>
        <div className="form-group col-12">
          <input
            type="text"
            id="ccode"
            className="form-control"
            value={ccode}
            onChange={(e) => setccode(e.target.value)}
            required
            placeholder="Enter key"
          />
        </div>
        <div className="form-group col-12">
          <button disabled={submitted ? true : false} type="submit"
                  className={`btn btn-${submitted ? 'secondary' : 'primary'}`}>{submitted ? 'Loading...' : 'Submit'}</button>
        </div>
      </form>
      {error && (<div className="form-group error">{error}</div>)}
    </div>
  );
};

export default Login;
