// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transcript-container {
  overflow-y: auto;
  max-height: 80%; }

@media screen and (orientation: portrait) {
  .transcript-container {
    max-height: 88%; } }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/Transcript.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe,EAAA;;AAGjB;EACE;IACE,eAAe,EAAA,EAChB","sourcesContent":[".transcript-container {\n  overflow-y: auto;\n  max-height: 80%;\n}\n\n@media screen and (orientation: portrait) {\n  .transcript-container {\n    max-height: 88%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
