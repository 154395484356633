import axios from "axios";
import {generateRandomString} from "./helpers";

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (request) => {
    const token = getLocal("auth_token");
    if (token) request.headers.authorization = `Bearer ${token}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        console.log('ATTEMPTING RESCUE OF ORIGINAL REQUEST');
        await getToken(true);
        return axios(originalRequest);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return Promise.reject(error);
  }
);


const setSess = (key, val) => {
  sessionStorage.setItem(key, val ? JSON.stringify(val) : false);
}

const getSess = (key) => {
  const v = sessionStorage.getItem(key);
  return v ? JSON.parse(v) : false;
}

const setLocal = (key, val) => {
  localStorage.setItem(key, val ? JSON.stringify(val) : false);
}

const getLocal = (key) => {
  const v = localStorage.getItem(key);
  return v ? JSON.parse(v) : false;
}

let tokenStatusTimer;
const getToken = async (fresh) => {
  const currentToken = getLocal("auth_token");
  const getTokenStatus = getSess("getToken_status");

  if (getTokenStatus) {
    if (tokenStatusTimer) {
      clearInterval(tokenStatusTimer);
    }
    tokenStatusTimer = setTimeout(() => {
      setSess("getToken_status", '');
    },2000);
    return getTokenStatus;
  }

  if ((!currentToken || fresh) && !getTokenStatus) {
    setSess("getToken_status",'wait');

    tokenStatusTimer = setTimeout(() => {
      setSess("getToken_status", '');
    },2000);

    const accessCode = getLocal('ccode');
    let user = getLocal('user');
    if (!user) {
      user = generateRandomString();
      setLocal('user',user);
    }

    const body = {
      accessCode,
      email:user + '@chaostheory.dev',
      password: 'password'
    }

    try {
      let tokenRes = await axios.post(`${process.env.REACT_APP_API}/auth/login`, body);

      console.log(tokenRes);

      if (tokenRes.data && tokenRes.status === 200) {
        setLocal("auth_token", tokenRes.data.access_token);
        setLocal("session_id", tokenRes.data.session._id);
        setLocal("d_id_key", tokenRes.data.apiKey);
      }

      return tokenRes;
    } catch (e) {
      console.log(e);

      if (e.response.status === 401 && /not registered/.test(e.response.data) ) {
        let tokenRes = await axios.post(`${process.env.REACT_APP_API}/auth/signup`, body);
        if (tokenRes.data && tokenRes.status === 200) {
          tokenRes = await axios.post(`${process.env.REACT_APP_API}/auth/login`, body);
        }

        if (tokenRes.data && tokenRes.data.access_token && tokenRes.status === 200) {
          setLocal("auth_token", tokenRes.data.access_token);
          setLocal("session_id", tokenRes.data.session._id);
          setLocal("d_id_key", tokenRes.data.apiKey);
          return tokenRes;
        }
      }

      setSess("getToken_status", '');
      throw new Error(e);
    }

  }

  return {data: currentToken, status: 200};
};

export {
  getSess,
  getLocal,
  setSess,
  setLocal,
  getToken
};
