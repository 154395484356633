import '../stylesheets/Transcript.scss';
import Message from "./Message";
import {useEffect, useRef} from "react";
import {useGetHistory} from "../../utils/hooks/historyHooks";

const Transcript = ({step}) => {
  const messagesEndRef = useRef(null);
  const { data, isLoading, isError, error } = useGetHistory(); // Use the hook to get chat history

  useEffect(() => {
    scrollToBottom();
  }, [data, step]); // Update dependency array to re-run effect when data changes

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  if (isLoading) return <div className="transcript-container">Loading...</div>; // Handle loading state
  if (isError) return <div className="transcript-container">Error: {error.message}</div>; // Handle error state

  return (
    <div className="flex-grow-1 p-3 transcript-container col-12 d-flex flex-column">
      {data && data.map((message, index) => (
        <Message key={index} message={message} />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};


export default Transcript;
