// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; }
  .avatar-container video {
    width: 100%;
    opacity: 1; }
  .avatar-container .talk-video {
    position: absolute;
    z-index: 1;
    opacity: 0;
    top: 0; }
  .avatar-container.talking .talk-video {
    opacity: 1; }
  .avatar-container.talking .idle-video {
    transition: opacity 100ms ease-in;
    transition-delay: 500ms;
    opacity: 0; }
  .avatar-container .stream-info {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    color: white;
    font-size: 1rem;
    max-width: 45%;
    background-color: rgba(255, 255, 255, 0.2); }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/Avatar.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB,EAAA;EANpB;IASI,WAAW;IACX,UAAU,EAAA;EAVd;IAcI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,MAAM,EAAA;EAjBV;IAsBM,UAAU,EAAA;EAtBhB;IAyBM,iCAAiC;IACjC,uBAAuB;IACvB,UAAU,EAAA;EA3BhB;IAgCI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,eAAe;IACf,cAAc;IACd,0CAAuC,EAAA","sourcesContent":[".avatar-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n\n  video {\n    width: 100%;\n    opacity: 1;\n  }\n\n  .talk-video {\n    position: absolute;\n    z-index: 1;\n    opacity: 0;\n    top: 0;\n  }\n\n  &.talking {\n    .talk-video {\n      opacity: 1;\n    }\n    .idle-video {\n      transition: opacity 100ms ease-in;\n      transition-delay: 500ms;\n      opacity: 0;\n    }\n  }\n\n  .stream-info {\n    position: fixed;\n    bottom: 1rem;\n    right: 1rem;\n    color: white;\n    font-size: 1rem;\n    max-width: 45%;\n    background-color: rgba(255,255,255,0.2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
