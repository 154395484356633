const {getLocal} = require("./dataFetching");

const RTCPeerConnection = (
  window.RTCPeerConnection ||
  window.webkitRTCPeerConnection ||
  window.mozRTCPeerConnection
).bind(window);

const DID_API = 'https://api.d-id.com';

const streamingClient = {
  fetchWithRetries: async function (url, options, retries = 1) {
    try {
      return await fetch(url, options);
    } catch (err) {
      if (retries <= window.streamingClient.maxRetryCount) {
        const delay = Math.min(Math.pow(2, retries) / 4 + Math.random(), window.streamingClient.maxDelaySec) * 1000;

        await new Promise((resolve) => setTimeout(resolve, delay));

        console.log(`Request failed, retrying ${retries}/${window.streamingClient.maxRetryCount}. Error:`,err);
        return window.streamingClient.fetchWithRetries(url, options, retries + 1);
      } else {
        console.log(`Max retries exceeded. error: ${err}`);
        console.log('RELOADING::');

        window.dispatchEvent(new CustomEvent('streaming-client-api',{
                                                                      detail:{
                                                                        message:'error',
                                                                        info:`Max retries exceeded. URL`+url,
                                                                        err
                                                                      }
                                                                    }));

        setTimeout(() => { window.location.reload() },1000);
        //throw new Error(`Max retries exceeded. error: ${err}`);
      }
    }
  },
  stopAllStreams: function () {
    console.log("stopAllStreams");
    console.dir(this.talkVideo);
    if (window.streamingClient.talkVideosrcObject) {
      console.log('stopping video streams');
      window.streamingClient.talkVideosrcObject.getTracks().forEach((track) => track.stop());
      window.streamingClient.talkVideosrcObject = null;
    }
  },
  closePeerConnection: function (pc = window.streamingClient.peerConnection) {
    console.log('closePeerConnection');
    //console.dir(pc);
    if (!pc) {
      console.log('no peer connection found, returning');
      return;
    }
    console.log('removing senders');
    pc.getSenders().forEach((sender) => {
      pc.removeTrack(sender);
    });
    console.log('stopping peer connection');
    pc.close();

    pc.removeEventListener('icegatheringstatechange', this.onIceGatheringStateChange, true);
    pc.removeEventListener('icecandidate', this.onIceCandidate, true);
    pc.removeEventListener('iceconnectionstatechange', this.onIceConnectionStateChange, true);
    pc.removeEventListener('connectionstatechange', this.onConnectionStateChange, true);
    pc.removeEventListener('signalingstatechange', this.onSignalingStateChange, true);
    pc.removeEventListener('track', this.onTrack, true);
    if (statsIntervalId) {
      console.log('clearInterval: ' + clearInterval);
      clearInterval(statsIntervalId);
    }
    console.log('stopped peer connection');
    if (pc === window.streamingClient.peerConnection) {
      window.streamingClient.peerConnection = null;
    }
  },
  onIceGatheringStateChange: function () {
    console.log('onIceGatheringStateChange: ' + window.streamingClient.peerConnection.iceGatheringState);
  },
  onIceCandidate: function (event) {
    console.log('onIceCandidate', event);
    if (event.candidate) {
      const { candidate, sdpMid, sdpMLineIndex } = event.candidate;
      const apiKey = getLocal('d_id_key');

      fetch(`${DID_API}/talks/streams/${streamId}/ice`, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${apiKey}`,
          'Content-Type': 'application/json',
          'accept': 'application/json'
        },
        body: JSON.stringify({
          candidate,
          sdpMid,
          sdpMLineIndex,
          session_id: sessionId,
        }),
      }).then(() => {
        console.log('onIceCandidate SUCCESS');
      }).catch((e) => {
        console.log('onIceCandidate CATCH ERROR',e);
      });
    }
  },
  onIceConnectionStateChange: async function () {

    const iceConnectionState = window.streamingClient.peerConnection?.iceConnectionState;
    console.log('onIceConnectionStateChange:' + iceConnectionState);

    if (iceConnectionState === 'failed' || iceConnectionState === 'closed' || iceConnectionState === 'disconnected') {
      console.log('onIceConnectionStateChange FAILED or CLOSED.  stopping streams and closing peer connection',iceConnectionState);

      window.dispatchEvent(new CustomEvent('streaming-client-api',{
        detail:{
          message:'error',
          info:`onIceConnectionStateChange: ${iceConnectionState}.  Attempting to reconnect...`
        }
      }));

      window.streamingClient.connectToDID();
      //window.location.reload();
    }
  },
  onConnectionStateChange: function () {
    const cs = window.streamingClient.peerConnection?.connectionState;
    console.log("onConnectionStateChange: " + cs);

    window.dispatchEvent(new CustomEvent('streaming-client-api',{
      detail:{
        message: cs || 'error',
        info: 'Connection State: '+cs,
        peerConnection: window.streamingClient.peerConnection
      }
    }));

  },
  onSignalingStateChange: function () {
    const ss = window.streamingClient.peerConnection?.signalingState;
    console.log("onSignalingStateChange: " + ss);

    window.dispatchEvent(new CustomEvent('streaming-client-api',{
      detail:{
        message:ss || 'error',
        info:'Signaling State: '+ss,
        peerConnection: window.streamingClient.peerConnection
      }
    }));

  },
  onTrack: function (event) {
    console.log("onTrack");
    /**
     * The following code is designed to provide information about wether currently there is data
     * that's being streamed - It does so by periodically looking for changes in total stream data size
     *
     * This information in our case is used in order to show idle video while no talk is streaming.
     * To create this idle video use the POST https://api.d-id.com/talks endpoint with a silent audio file or a text script with only ssml breaks
     * https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html#break-tag
     * for seamless results use `config.fluent: true` and provide the same configuration as the streaming video
     */
    if (!event.track) {
      console.log("onTrack DOES NOT have track");
      return;
    }
    else {
      console.log("onTrack has a track");
    }

    if (statsIntervalId) {
      clearInterval(statsIntervalId);
    }

    statsIntervalId = setInterval(async () => {
      try {
        const stats = await window.streamingClient.peerConnection?.getStats(event.track);
        stats && stats.forEach((report) => {
          if (report.type === 'inbound-rtp' && (report.mediaType || report.kind) === 'video') {
            //console.log("Got inbound video.  bytesReceived:", report.bytesReceived);

            const videoStatusChanged = videoIsPlaying !== (report.bytesReceived > window.streamingClient.lastBytesReceived);

            if (videoStatusChanged) {
              videoIsPlaying = report.bytesReceived > window.streamingClient.lastBytesReceived;
              window.streamingClient.onVideoStatusChange(videoIsPlaying, event.streams[0]);
            }
            window.streamingClient.lastBytesReceived = report.bytesReceived;
          }
        });
        return;
      }
      catch (e) {
        clearInterval(statsIntervalId);
        console.log("### ERROR1 ###",statsIntervalId);
        console.dir(e);

        window.dispatchEvent(new CustomEvent('streaming-client-api',{
          detail:{
            message:'error',
            info:`No peerConnection or Stats, reconnecting...`,
            peerConnection: window.streamingClient
          }
        }));

        window.streamingClient.connectToDID();
        //window.location.reload(); // TODO: this is a hack, figure out how to graceful reconnect.
      }
    }, 500);
    console.log('ONTRACK INTERVAL ID:',statsIntervalId);
  },
  onTrackOriginal: function (event) {
    console.log("onTrackOriginal");
    /**
     * The following code is designed to provide information about wether currently there is data
     * that's being streamed - It does so by periodically looking for changes in total stream data size
     *
     * This information in our case is used in order to show idle video while no talk is streaming.
     * To create this idle video use the POST https://api.d-id.com/talks endpoint with a silent audio file or a text script with only ssml breaks
     * https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html#break-tag
     * for seamless results use `config.fluent: true` and provide the same configuration as the streaming video
     */
    if (!event.track) return;

    if (statsIntervalId) {
      clearInterval(statsIntervalId);
    }

    statsIntervalId = setInterval(async () => {
      const stats = await window.streamingClient.peerConnection.getStats(event.track);
      stats.forEach((report) => {
        if (report.type === 'inbound-rtp' && (report.mediaType || report.kind) === 'video') {
          const videoStatusChanged = videoIsPlaying !== (report.bytesReceived > window.streamingClient.lastBytesReceived);

          if (videoStatusChanged) {
            videoIsPlaying = report.bytesReceived > window.streamingClient.lastBytesReceived;
            window.streamingClient.onVideoStatusChange(videoIsPlaying, event.streams[0]);
          }
          window.streamingClient.lastBytesReceived = report.bytesReceived;
        }
      });
    }, 500);
  },
  onTrackNew: function (event) {
    console.log("onTrackNew");
    /**
     * The following code is designed to provide information about wether currently there is data
     * that's being streamed - It does so by periodically looking for changes in total stream data size
     *
     * This information in our case is used in order to show idle video while no talk is streaming.
     * To create this idle video use the POST https://api.d-id.com/talks endpoint with a silent audio file or a text script with only ssml breaks
     * https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html#break-tag
     * for seamless results use `config.fluent: true` and provide the same configuration as the streaming video
     */
    if (!event.track) {
      console.log("onTrack DOES NOT have track");
      return;
    }
    else {
      console.log("onTrack has a track");
    }

    if (typeof statsIntervalId != 'undefined') {
      clearInterval(statsIntervalId);
    }

    var getStatsCallCount = 0;
    statsIntervalId = setInterval(async () => {
      console.log("waiting for stats on timer: " + statsIntervalId);
      getStatsCallCount++;
      if (getStatsCallCount > 100) {
        clearInterval(statsIntervalId);
        return;
      }
      const stats = await window.streamingClient.peerConnection.getStats(event.track);
      stats.forEach((report) => {
        if (report.type === 'inbound-rtp' && (report.mediaType || report.kind) === 'video') {
          console.log("Got inbound video.  bytesReceived:", report.bytesReceived);
          if (report.bytesReceived === 0) {
            // Ummmmm
            console.log("Got inbound video of ZERO length? clearInterval?");
            console.log("Clearing interval: " + statsIntervalId);
            clearInterval(statsIntervalId);
          }

          const videoStatusChanged = videoIsPlaying !== (report.bytesReceived > window.streamingClient.lastBytesReceived);

          if (videoStatusChanged) {
            videoIsPlaying = report.bytesReceived > window.streamingClient.lastBytesReceived;
            window.streamingClient.onVideoStatusChange(videoIsPlaying, event.streams[0]);
            clearInterval(statsIntervalId);
          }
          window.streamingClient.lastBytesReceived = report.bytesReceived;
        }
      });
    }, 500);
  },
  onVideoStatusChange: function (videoIsPlaying, stream) {
    console.log("onVideoStatusChange videoIsPlaying: " + videoIsPlaying);
    window.streamingClient.videoPlayCount++;
    console.log("videoPlayCount: " + window.streamingClient.videoPlayCount);
    let status;
    if (videoIsPlaying) {
      status = 'streaming';
      const remoteStream = stream;
      console.log("onVideoStatusChange setVideoElement...");
      window.streamingClient.setVideoElement(remoteStream);
      console.log("onVideoStatusChange DONE setVideoElement");

      window.dispatchEvent(new CustomEvent('streaming-client-api',{detail:{message:'playing'}}));
    } else {
      status = 'empty';
      if (window.streamingClient.videoPlayCount > 1) {
        console.log("before playIdleVideo");
        window.streamingClient.playIdleVideo();
      }
    }
    console.log("status: " + status);

  },
  setVideoElement: function (stream) {
    console.log('setVideoElement()');
    if (!stream) return;

    window.getTalkVideo().srcObject = stream;

    window.getTalkVideo().addEventListener('canplay', (ev) => {
      console.log('setVideoElementtalkVideo.srcObject is now:' + window.getTalkVideo().srcObject);

      // safari hotfix
      if (window.getTalkVideo().paused) {
        window.getTalkVideo()
          .play()
          .then((_) => { })
          .catch((e) => { });
      }
    }, { once: true });

  },
  playIdleVideo: function () {
    //idle video plays in background on loop
    window.dispatchEvent(new CustomEvent('streaming-client-api',{detail:{message:'idle'}}));
  },
  createPeerConnection: async function (offer, iceServers) {
    console.log('createPeerConnection');
    if (!window.streamingClient.peerConnection) {
      console.log('window.peerConnection not found, creating');
      window.streamingClient.peerConnection = new RTCPeerConnection({ iceServers });
      window.streamingClient.peerConnection.addEventListener('icegatheringstatechange', this.onIceGatheringStateChange, true);
      window.streamingClient.peerConnection.addEventListener('icecandidate', this.onIceCandidate, true);
      window.streamingClient.peerConnection.addEventListener('iceconnectionstatechange', this.onIceConnectionStateChange, true);
      window.streamingClient.peerConnection.addEventListener('connectionstatechange', this.onConnectionStateChange, true);
      window.streamingClient.peerConnection.addEventListener('signalingstatechange', this.onSignalingStateChange, true);
      window.streamingClient.peerConnection.addEventListener('track', this.onTrack, true);
    }
    else {
      console.log('window.streamingClient.peerConnection already exists, skipping create');
    }
    console.dir(iceServers);

    console.dir(offer);
    // let sessionDescription = new RTCSessionDescription(offer);
    // console.dir(sessionDescription);

    // console.log('creating peer connection for sessionDescription.origin: ' + sessionDescription.origin);
    // TODO: window.streamingClient.peerConnections
    // var pc = peerConnections[ targetMid ];
    // var targetMid = msg.origin;

    await window.streamingClient.peerConnection.setRemoteDescription(offer);
    console.log('set remote sdp OK');

    const sessionClientAnswer = await window.streamingClient.peerConnection.createAnswer();
    console.log('create local sdp OK');

    await window.streamingClient.peerConnection.setLocalDescription(sessionClientAnswer);
    console.log('set local sdp OK');

    return sessionClientAnswer;
  },
  connectToDID: async function () {
    console.log('connectToDID()');

    window.dispatchEvent(new CustomEvent('streaming-client-api',{detail:{message:'connecting',info:'Connecting to avatar...'}}));

    if (window.streamingClient.peerConnection && window.streamingClient.peerConnection === 'connected') {
      console.log('connectToDID, already connected, returning');
      window.dispatchEvent(new CustomEvent('streaming-client-api',{detail:{message:'connected'}}));
      return;
    }

    this.stopAllStreams();
    this.closePeerConnection();
    const apiKey = getLocal('d_id_key');

    const sessionResponse = await this.fetchWithRetries(`${DID_API}/talks/streams`, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${apiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        source_url: 'https://openai-chatbot-public.s3.us-west-2.amazonaws.com/female-03.png',
      }),
    });

    const { id: newStreamId, offer, ice_servers: iceServers, session_id: newSessionId } = await sessionResponse.json();
    streamId = newStreamId;
    sessionId = newSessionId;

    try {
      sessionClientAnswer = await this.createPeerConnection(offer, iceServers);
    } catch (e) {
      console.log('error during streaming setup', e);
      this.stopAllStreams();
      this.closePeerConnection();

      window.dispatchEvent(new CustomEvent('streaming-client-api',{
        detail:{
          message:'error',
          info:'Error during streaming setup',
          e
        }
      }));

      return;
    }

    const sdpResponse = await fetch(`${DID_API}/talks/streams/${streamId}/sdp`, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${apiKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        answer: sessionClientAnswer,
        session_id: sessionId,
      }),
    }).then(() => {
      console.log('sdpResponse SUCCESS');
    }).catch((e) => {
      console.log('sdpResponse CATCH ERROR',e);
    });

    console.log('sdpResponse',sdpResponse);

    window.dispatchEvent(new CustomEvent('streaming-client-api',{detail:{message:'connected'}}));
  },
  playStreamText: async function (text) {
    console.log(`playStreamText(${text})`);
    // connectionState not supported in firefox
    let signalingState = window.streamingClient.peerConnection?.signalingState;
    if (!signalingState) {
      console.log('resume reconnecting...');
      await this.connectToDID();
      console.log('resuming');
      return this.playStreamText(text);
    }
    if (signalingState) {
      console.log("playStreamText signalingState:" + signalingState);
      let isConnected = false;
      if (signalingState === 'stable' || signalingState === 'connected') {
        isConnected = true;
      }

      if (!isConnected) {
        console.log("playStreamText reconnecting");
        await this.connectToDID();
        console.log("playStreamText reconnected");
        return this.playStreamText(text);
      }

      // TODO Wait for stable connection here with some sorta timeout???
      if (isConnected) {
        const apiKey = getLocal('d_id_key');
        let uri = `${DID_API}/talks/streams/${streamId}`;
        console.log("playStreamText isConnected");

        const options = {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: `Basic ${apiKey}`
          },
          body: JSON.stringify({
            script: {
              type: 'text',
              subtitles: 'false',
              provider: { type: 'microsoft', voice_id: 'en-US-NancyNeural' },
              ssml: 'true',
              input: text
            },
            config: { fluent: 'false', pad_audio: '0.0', stitch: true },
            audio_optimization: '2',
            session_id: sessionId
          })
        };

        const talkResponse = await window.streamingClient.fetchWithRetries(uri, options);
        if (talkResponse && talkResponse.status === 400) {
          console.log("playStreamText reconnecting");
          await this.connectToDID();
          console.log("playStreamText reconnected");
          return this.playStreamText(text);
        }
        console.log('talkResponse',talkResponse);
      }
    }
    else {
      console.log("playStream missing signalingState!!!");
    }
  },
}

Object.assign(window, { streamingClient });

let maxRetryCount = 10;
Object.assign(window.streamingClient, { maxRetryCount });
let maxDelaySec = 10;
Object.assign(window.streamingClient, { maxDelaySec });
let peerConnection;
Object.assign(window.streamingClient, { peerConnection });
let streamId;
Object.assign(window.streamingClient, { streamId });
let sessionId;
Object.assign(window.streamingClient, { sessionId });
let sessionClientAnswer;
Object.assign(window.streamingClient, { sessionClientAnswer });
let statsIntervalId;
Object.assign(window.streamingClient, { statsIntervalId });
let videoIsPlaying;
Object.assign(window.streamingClient, { videoIsPlaying });
let lastBytesReceived;
Object.assign(window.streamingClient, { lastBytesReceived });
let videoPlayCount;
Object.assign(window.streamingClient, { videoPlayCount });

window.streamingClient.videoPlayCount = 0; // Use this to avoid playing the first video twice and getting a flicker on the page

window.getTalkVideo = function () {
  return document.getElementById('talk-video');
};

window.getTalkVideoWrapper = function () {
  return document.getElementById('talk-video-wrapper');
};
window.getIdleVideo = function () {
  return document.getElementById('idle-video');
};
window.getIdleVideoWrapper = function () {
  return document.getElementById('idle-video-wrapper');
};

window.onload = async function () {
  console.log('window.onload');
  const talkVideo = document.getElementById('talk-video');
  Object.assign(window.streamingClient, { talkVideo });

  const idleVideo = document.getElementById('idle-video');
  Object.assign(window.streamingClient, { idleVideo });

  await window.streamingClient.connectToDID();

}

module.exports = streamingClient;
