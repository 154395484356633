import '../stylesheets/Message.scss';

const Message = ({ message }) => {
  const { text, mediaType, mediaUrl, sender } = message;

  const renderMedia = () => {
    if (mediaType === 'image') {
      return <img src={mediaUrl} alt="" />;
    } else if (mediaType === 'video') {
      return (
        <video controls>
          <source src={mediaUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return null;
  };

  return (
    <div className={`d-flex flex-column message ${sender === 'AI' ? 'ai' : 'human'}`}>
      <span>{text}</span>
      {mediaType && renderMedia()}
    </div>
  );
};

export default Message;
