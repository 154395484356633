// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container img {
  width: 36.72vw;
  max-width: 74%; }

.transcript .header-container {
  flex-grow: 0 !important; }

@media screen and (max-width: 1366px) {
  .avatar .header-container img {
    width: 74%; }
  .transcript .header-container img {
    max-width: 36.72vw; } }

@media screen and (orientation: portrait) {
  .avatar .header-container img,
  .transcript .header-container img {
    width: 100%;
    max-width: 49vw; } }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/Header.scss"],"names":[],"mappings":"AAAA;EAEI,cAAc;EACd,cAAc,EAAA;;AAIlB;EACE,uBAAuB,EAAA;;AAGzB;EACE;IAGI,UAAU,EAAA;EAGd;IAEI,kBAAkB,EAAA,EAEnB;;AAIL;EACE;;IAIM,WAAW;IACX,eAAe,EAAA,EAChB","sourcesContent":[".header-container {\n  img {\n    width: 36.72vw;\n    max-width: 74%;\n  }\n}\n\n.transcript .header-container {\n  flex-grow: 0 !important;\n}\n\n@media screen and (max-width: 1366px) {\n  .avatar .header-container {\n    img {\n      //max-width: 36.72vw;\n      width: 74%;\n    }\n  }\n  .transcript .header-container {\n    img {\n      max-width: 36.72vw;\n      //width: 74%;\n    }\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .avatar,\n  .transcript {\n    .header-container {\n      img {\n        width: 100%;\n        max-width: 49vw;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
